var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullScreenLoading && _vm.isPricingTierEnabled,
          expression: "fullScreenLoading&&isPricingTierEnabled",
        },
      ],
      staticClass: "manager-container module-main-body",
    },
    [
      _c(
        "el-row",
        { staticClass: "module-content-top-bar" },
        [
          _c("el-col", { attrs: { sm: 8, md: 10, lg: 10 } }, [
            _c("h1", { staticClass: "top-bar-title" }, [
              _vm._v("Set Customer Tier"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "div-main-body-left-right module-content-main-view",
          attrs: { type: "flex" },
        },
        [
          _c(
            "el-col",
            { staticStyle: { padding: "20px" }, attrs: { span: 24 } },
            [
              _c(
                "div",
                { staticStyle: { height: "100%", "overflow-y": "auto" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-end",
                      staticStyle: { "padding-bottom": "10px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "query-item search-bar flex-end" },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "round-input",
                              attrs: { placeholder: "Search Company" },
                              model: {
                                value: _vm.searchCustomer,
                                callback: function ($$v) {
                                  _vm.searchCustomer = $$v
                                },
                                expression: "searchCustomer",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchCustomerHandler },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.isPricingTierEnabled
                    ? _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.data,
                            border: true,
                            "header-cell-style": _vm.HEADER_CELL_STYLE,
                            "cell-style": _vm.CELL_STYLE,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "Company Name", "min-width": 240 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(_vm._s(scope.row.companyName)),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1119022845
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Company Code (SAGE300)",
                              "min-width": 200,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            "allow-create": "",
                                            placeholder: "Choose or input",
                                          },
                                          on: {
                                            "visible-change": function (
                                              $event
                                            ) {
                                              return _vm.getVictoryCompanyCodeList(
                                                $event,
                                                scope.row.organizationId
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.handleUpdateCustomerPricingTier(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.victoryCompanyCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "victoryCompanyCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.victoryCompanyCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.victoryCompanyCodeList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              81369816
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Credit Limit", "min-width": 180 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-input-number",
                                        {
                                          attrs: {
                                            type: "number",
                                            controls: false,
                                            min: 0,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleUpdateCustomerPricingTier(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.creditLimit,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "creditLimit",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.creditLimit",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "append" },
                                              slot: "append",
                                            },
                                            [_vm._v("USD")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3488095539
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Tier", "min-width": 150 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleUpdateCustomerPricingTier(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.tierId,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "tierId", $$v)
                                            },
                                            expression: "scope.row.tierId",
                                          },
                                        },
                                        _vm._l(_vm.tiers, function (tier) {
                                          return _c("el-option", {
                                            key: tier.tierId,
                                            attrs: {
                                              label: tier.title,
                                              value: tier.tierId,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              654985426
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Last Updated", "min-width": 150 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDateTimeMixin(
                                            scope.row.updatedTime
                                          )
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              6892453
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Updated By", "min-width": 150 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [_vm._v(_vm._s(scope.row.updatedBy))]
                                  },
                                },
                              ],
                              null,
                              false,
                              2389277747
                            ),
                          }),
                        ],
                        1
                      )
                    : _c("div", [_vm._v("The Pricing Tier Is Not Activated")]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }